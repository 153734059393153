<!-- AboutUs.vue -->
<template>
  <section class="sna-section about-us " ref="about" id="about">
    <!-- Added mt-5 (margin-top) and mb-5 (margin-bottom) -->
    <div class="container">
        <div class="sna-section__title">
            <h2 class="about-title">About Us</h2>
            <div class=" sna-section__title-border"></div>
        </div>
      
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="about-logo">
            <img src="../assets/images/sna-about.jpg" alt="Logo" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="about-description">
            <p>
              At SNA Property Services, we are committed to providing our
              clients with the highest level of service. Our team of
              professionals is highly skilled and experienced in all aspects of
              property management, commercial cleaning, and property
              maintenance. We take pride in our work and strive to exceed our
              clients' expectations.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>



export default {

};
</script>

<style scoped>
/* Add your CSS styles for about us section here */
.about-title {
  text-align: center;
}

.about-logo img {
  max-width: 100%;
}

.about-description p {
  margin-bottom: 20px;
  font-size: 1.4rem;
  line-height: 3rem;
  padding-left: 2rem;
}
section {
  padding-top: 100px;
}













</style>
