<script setup>
import { GoogleMap, Marker } from "vue3-google-map";

const center = { lat: 43.78166, lng: -79.24492 };
</script>
<template>
  <section class="contact-us" ref="contact" id="contact">
    <div class="container">
      <div class="sna-section__title">
        <h2 class="about-title">Contact Us</h2>
        <div class="sna-section__title-border"></div>
      </div>
      <div class="row mb-4">
        <div class="col-lg-4 col-sm-12 mb-sm-3">
          <div class="card">
            <div class="card-body d-flex">
              <div class="d-flex align-items-center contact-icon">
                <font-awesome-icon icon="phone" />
              </div>
              <div class="ps-3">
                <h5 class="card-title">Phone</h5>
                <p class="card-text">+1 416 837 9395</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-12 mb-sm-3">
          <div class="card">
            <div class="card-body d-flex">
              <div class="d-flex align-items-center contact-icon">
                <font-awesome-icon icon="location-dot" />
              </div>
              <div class="ps-3">
                <h5 class="card-title">Address</h5>
                <p class="card-text">
                  705,11 Lee Center drive Scarborough,ON, Canada
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-12 mb-sm-3">
          <div class="card">
            <div class="card-body d-flex">
              <div class="d-flex align-items-center contact-icon">
                <font-awesome-icon icon="envelope" />
              </div>
              <div class="ps-3">
                <h5 class="card-title">Email</h5>
                <a href="mailto:snapropertyservices@gmail.com" class="card-text">snapropertyservices@gmail.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row map-area">
        <div class="col-lg-6 col-sm-12 mb-sm-3">
          <!-- Google Map Component -->
          <GoogleMap
            api-key="AIzaSyAofOuRQtS4ZkbANZ95HYZAJGe8iLTYn2E"
            style="width: 100%; height: 430px"
            :center="center"
            :zoom="15"
          >
            <Marker :options="{ position: center }" />
          </GoogleMap>
        </div>
        <div class="col-lg-6 col-sm-12">
          <!-- Contact Form -->
          <div class="contact-form">
            <h3>Get in Touch</h3>
            <div v-if="showSuccess" class="alert alert-success" role="alert">
              Your message has been sent successfully.
            </div>
            <form @submit.prevent="submitForm">
              <div class="form-group">
                <input
                  v-model="formData.name"
                  type="text"
                  class="form-control"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div class="form-group">
                <input
                  v-model="formData.email"
                  type="email"
                  class="form-control"
                  placeholder="Your Email"
                  required
                />
              </div>
              <div class="form-group">
                <textarea
                  v-model="formData.message"
                  class="form-control"
                  rows="5"
                  placeholder="Your Message"
                  required
                ></textarea>
              </div>
              <button type="submit" class="btn btn-warning">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
/* Add your CSS styles for contact us section here */
.card {
  height: 100%;
  background-color: #fff;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ffd7001a;
 
}
.contact-icon {
  width: 55px;
  min-width: 55px;
  height: 55px;
  border: 2px solid gold;
  border-radius: 4rem;
  text-align: center;
  place-content: center;
  svg {
    font-size: 1.2rem;
  }
}
.map-area {
  margin-top: 4rem;
}
.contact-us {
  padding-top: 100px;
}

.contact-form {
  padding: 20px;
  background-color: #ffd7002b;
  border-radius: 10px;
  height: 100%;
  form {
    display: flex;
    flex-flow: column;
    gap: 1.2rem;
    .form-label {
      font-size: 1.1rem;
      font-weight: 500;
    }
    .form-control {
      min-height: 50px;
      background-color: #ffff !important;
      border: 0 !important;
    }
    button {
      height: 50px;
      font-size: 1.2rem;
    }
  }
}

.contact-form h3 {
  margin-bottom: 20px;
}
a.card-text{
  text-decoration: none;
  color: "#212529";
}




</style>
<script>
import emailjs from "emailjs-com";

export default {
  data() {
    return {
      formData: {
        name: "",
        email: "",
        message: "",
      },
      showSuccess: false,
    };
  },

  methods: {
    submitForm() {
      // Send email using EmailJS
      emailjs
        .send(
          "service_g3qwlp3",
          "template_38r8uzy",
          this.formData,
          "kp8xM0-GZ5PVb5eAq"
        )
        .then((response) => {
          console.log("Email sent successfully:", response);
          this.showSuccess = true; // Show success notification
          // Reset form data after successful submission
          this.formData = {
            name: "",
            email: "",
            message: "",
          };
        })
        .catch((error) => {
          console.error("Error sending email:", error);
          // Handle error
        });
    },
  },
};
</script>
