<template>
  <div
    id="carouselExampleIndicators"
    class="carousel slide"
    data-ride="carousel"
    ref="currentSlide"
    on-mouseover="#000000"
    on-animationend="true"
  >
    <div class="carousel-indicators">
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="0"
        class="active"
        aria-current="true"
        aria-label="Slide 1"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="1"
        aria-label="Slide 2"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="2"
        aria-label="Slide 3"
      ></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img
          class="d-block w-100"
          src="../assets/images/slider-1.jpg"
          alt="First slide"
        />
        <div class="overlay"></div>
        <div class="carousel-caption d-flex flex-column align-items-center">
          <img
            class="d-block"
            src="../assets/images/logo--header.png"
            alt="Logo"
          />
          <h1 class="animated-title">Commercial Cleaning</h1>
          <p class="animated-desc">Cleanliness Beyond Expectations</p>
        </div>
      </div>
      <div class="carousel-item">
        <img
          class="d-block w-100"
          src="../assets/images/slider-2.jpg"
          alt="Second slide"
        />
        <div class="overlay"></div>
        <div class="carousel-caption d-flex flex-column align-items-center">
          <img
            class="d-block"
            src="../assets/images/logo--header.png"
            alt="Logo"
          />
          <h1 class="animated-title">Property Maintenance</h1>
          <p class="animated-desc">
            Preserving Excellence, Every Step of the Way
          </p>
        </div>
      </div>
      <div class="carousel-item">
        <img
          class="d-block w-100"
          src="../assets/images/slider-3.jpg"
          alt="Third slide"
        />
        <div class="overlay"></div>
        <div class="carousel-caption d-flex flex-column align-items-center">
          <img
            class="d-block"
            src="../assets/images/logo--header.png"
            alt="Logo"
          />
          <h1 class="animated-title">Property Management</h1>
          <p class="animated-desc">Efficiency. Expertise. Excellence.</p>
        </div>
      </div>
    </div>
    <a
      class="carousel-control carousel-control-prev"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    </a>
    <a
      class="carousel-control carousel-control-next"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
    </a>
  </div>
</template>

<script>
import { ref } from "vue";
import { gsap } from "gsap";

export default {
  setup() {
    const currentSlide = ref(null);

    // Function to fade in the slide
    const fadeInSlide = () => {
      gsap.fromTo(
        currentSlide.value,
        { opacity: 1 },
        { opacity: 0, duration: 0.5 }
      );
    };

    // You can call fadeInSlide when the slide changes, for example, when navigating to the next slide

    return { currentSlide, fadeInSlide };
  },
};
</script>

<style scoped>
/* Add your CSS styles for the slider here */
.carousel-caption {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
  color: #fff;
  z-index: 2;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
  z-index: 1;
}

.animated-title {
  font-size: 4rem; /* Adjust font size as needed */
  font-weight: 600;
  animation: fadeInDown 1s ease;
  text-shadow: 2px 2px 2px #000000;
}

.animated-desc {
  animation: fadeInUp 1s ease;
  font-size: 1.8rem;
}
.carousel-control {
  z-index: 9999;
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

</style>
