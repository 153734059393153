<!-- NavigationPanel.vue -->
<template>
    <nav class="navbar  sticky-top navbar-expand-lg navbar-dark bg-dark">
      <div class="container">
        <!-- Logo on the left -->
       
        <a href="#home" class="navbar-brand position-relative"> <img class="d-block sna-logo" src="../assets/images/logo.jpg" alt="First slide"></a>
  
        <!-- Toggler button for mobile -->
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
  
        <!-- Links on the right -->
        <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
          <ul class="navbar-nav">
            <li class="nav-item">
             <a href="#home" class="nav-link text-gold">Home</a>
            </li>
            <li class="nav-item">
                <a href="#about" class="nav-link text-gold">About</a>
            </li>
            <li class="nav-item">
                <a href="#services" class="nav-link text-gold">Services</a>
             
            </li>
            <li class="nav-item">
                <a href="#contact" class="nav-link text-gold">Contact</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </template>
  
  <script>
  export default {
    // Add your navigation panel logic here
  }
  </script>
  
  <style scoped>
  /* Add your CSS styles for navigation panel here */
  .text-gold {
    color: gold !important;
  }
.navbar{
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.navbar-nav{
    gap:2rem;
    font-size: 1.2rem;
}
.sna-logo{
    position: absolute;
    z-index: 9999;
    border-radius: 4px;
}




















  </style>
  