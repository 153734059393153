import 'bootstrap/dist/css/bootstrap.min.css';
import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';
import LayoutSection from "./components/LayoutSection.vue";

import About from './components/AboutUs.vue';
import Services from './components/ServicesSection.vue';
import ContactUs from './components/ContactUs.vue';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faPhone, faEnvelope, faLocationDot)
const router = createRouter({
    history: createWebHashHistory(),
    routes: [
      {
        path: '/',
        component: LayoutSection,
        children: [
          { path: '/', component: LayoutSection }, // Default route
          { path: 'about', component: About },
          { path: 'services', component: Services },
          { path: 'contact', component: ContactUs }
        ]
      }
    ]
  });
  
  createApp(App).use(router).component('font-awesome-icon', FontAwesomeIcon).mount('#app');



