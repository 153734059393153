<template>
  <div id="home">
    <NavigationBar />

    <SliderSection/>
    <AboutUs/>
    <ServicesSection/>
    <ContactUs />
    <!-- This is where your page content will be rendered -->

    <FooterComponent />
  </div>
</template>

<script>
import NavigationBar from './NavigationBar.vue';
import SliderSection from './SliderSection';
import FooterComponent from './FooterComponent.vue';
import AboutUs from './AboutUs';
import ServicesSection from './ServicesSection';
import ContactUs from './ContactUs';

export default {
  components: {
    NavigationBar,
    SliderSection,
    AboutUs,
    ServicesSection,
    ContactUs,
    FooterComponent
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/styles.scss';
/* Other component-specific styles */
</style>
