
<template>
  <div id="app">
    <LayoutSection>
      <!-- Your main content goes here -->
      <router-view></router-view>
    </LayoutSection>
  </div>
</template>

<script>
import LayoutSection from "./components/LayoutSection.vue";


export default {
  name: "App",
  components: {
    LayoutSection,
  },
};
</script>

<style>
/* Add global styles here */
</style>