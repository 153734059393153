<!-- Footer.vue -->
<template>
  <footer class="footer bg-dark text-white">
    <div class="container">
      <div class="row">
        <!-- First column -->
        <div class="col-lg-4 col-md-6 mb-md-3  mb-sm-4 col-sm-12">
          <h4 class="text-white">About Us</h4>
          <a href="#home" class="position-relative"> <img class="d-block sna-logo" src="../assets/images/logo.jpg" alt="First slide"></a>
          <p class="text-gold">At SNA Property Services, we are committed to providing our clients with the highest level of service.</p>
          
   
        </div>
        <!-- Second column -->
        <div class="col-lg-3 col-md-6 mb-md-3 mb-sm-4 col-sm-12">
          <h4 class="text-white">Quick Links</h4>
          <ul>
            <li><a class="text-gold">Home</a></li>
            <li><a  class="text-gold">About</a></li>
            <li><a  class="text-gold">Services</a></li>
            <li><a  class="text-gold">Contact</a></li>
          </ul>
        </div>
        <!-- Third column -->
        <div class="col-lg-3 col-md-6 mb-md-3 mb-sm-4 col-sm-12">
          <h4 class="text-white">Contact Us</h4>
          <p class="text-gold"> 705,11 Lee Center drive Scarborough,ON, Canada</p>
          <a href="mailto:snapropertyservices@gmail.com" class="text-gold mb-4" >snapropertyservices@gmail.com</a><p></p>
          <p class="text-gold">Phone: +1 416 837 9395</p>
        </div>
        <!-- Fourth column -->
        <div class="col-lg-2 col-md-6 mb-md-3 mb-sm-4 col-sm-12">
          <h4 class="text-white">Follow Us</h4>
          <ul class="social-icons">
            <li><a ><i class="bi bi-facebook text-gold"></i></a></li>
            <li><a ><i class="bi bi-twitter text-gold"></i></a></li>
            <li><a ><i class="bi bi-instagram text-gold"></i></a></li>
            <li><a ><i class="bi bi-linkedin text-gold"></i></a></li>
          </ul>
        </div>
      </div>
      <hr>
      <div class="text-center">
        <p class="text-gold">&copy; 2024 SNA Property Services. All rights reserved. | <span>Website Designed &amp; Developed by <a class="text-gold" href="http://stylaxis.com/" target="_blank">Stylaxis</a></span></p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  // Footer logic can be added here if needed
}
</script>

<style scoped>
.footer {
  margin-top: 6rem;
  padding-top: 100px;
  padding-bottom: 50px;
  background-color: #0e0e0e !important;
  img{
    max-width: 120px;
    position: relative;
    top: -10px;
    left: -20px;
  }
  h4{
    margin-bottom: 1.5rem;
  }
  p{
    line-height: 2rem;
  }
  ul{
    margin: 0;
    padding: 0;
    line-height: 2.5rem;
    li{
      list-style: none;
    }
  }
}

.text-gold {
  color: gold;
  text-decoration: none;
}

.social-icons {
  list-style: none;
  padding: 0;
}

.social-icons li {
  display: inline-block;
  margin-right: 1rem;
  font-size: 1.8rem
}

.social-icons li:last-child {
  margin-right: 0;
}






















</style>
