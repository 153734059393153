<!-- Services.vue -->
<template>
  <section class="services" id="services" ref="services">
    <div class="container">
      <div class="sna-section__title">
        <h2 class="about-title">Our Services</h2>
        <div class="sna-section__title-border"></div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-sm-12 mb-sm-4">
          <div class="sna-service-card">
            <div class="service-content">
              <img
                class="sna-service-img"
                src="../assets/images/service-1.jpg"
                alt="Service Icon"
              />
              <h3 class="sna-service-header">Commercial Cleaning</h3>
              <p class="sna-service-desc">
                At SNA Property Services, we understand the importance of
                maintaining a clean environment for your business. Our team of
                experts is here to provide you with the best commercial cleaning
                services tailored to your needs. We use only the finest
                equipment and cleaning products to ensure your premises are
                spotless.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-12 mb-sm-4">
          <div class="sna-service-card">
            <div class="service-content">
              <img
                class="sna-service-img"
                src="../assets/images/service-2.jpg"
                alt="Service Icon"
              />
              <h3 class="sna-service-header">Property Maintenance</h3>
              <p class="sna-service-desc">
                We specialize in property maintenance for commercial and
                residential properties. Our team of professionals will ensure
                that your property is well-maintained and in excellent
                condition. We offer a range of services including repairs,
                landscaping, and janitorial services.
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-12 ">
          <div class="sna-service-card">
            <div class="service-content">
              <img
                class="sna-service-img"
                src="../assets/images/service-3.jpg"
                alt="Service Icon"
              />
              <h3 class="sna-service-header">Property Management</h3>
              <p class="sna-service-desc">
                Our property management services are designed to take the hassle
                out of owning a property. We handle everything from tenant
                screening to rent collection, and we make sure that your
                property is always in compliance with local laws and
                regulations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};

</script>

<style scoped>
/* Add your CSS styles for services section here */
.services {
  padding-top: 100px;
}

.section-title {
  text-align: center;
}

.sna-service-card {
    background-color: #fff;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ffd7001a;
}

.service-content {
  padding: 20px;
  text-align: center;
}
.sna-service-header {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  line-height: 2rem;
}
.sna-service-desc {
  text-align: justify;
  line-height: 1.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.service-content img {
  width: 100%;
  margin-bottom: 20px;
}











</style>
